<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <aside class="page__sidebar">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Materiais coletados</h1>
              <h5 class="sidebar__subtitle">
                Histórico de materiais coletados.
              </h5>
            </div>
          </aside>

          <div class="page__content">
            <div class="tabs__flex">
              <div class="tabs__search">
                <form class="search__form">
                  <input
                    class="form__input"
                    type="text"
                    placeholder="Pesquisar..."
                    @input="debounceInput"
                  />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </form>
              </div>

              <div class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>

            <div class="modal__content modal__title strong" style="margin-top:25px" v-if="load && !materials.length">
              <mark>Carregando...</mark>
            </div>
            <div class="material__list">
              <!-- Erro -->
              <div class="modal__content " v-if="error">
                <h4>{{ error }}</h4>
              </div>
              <!-- Material coletado -->
              <div
                v-for="material in materials"
                :key="material.id"
                class="material__item"
              >
                <img
                  class="material__item--image"
                  :src="$help.image(material.photo)"
                  alt=""
                />

                <div class="material__item--content">
                  <h4>
                    {{ material.name }}
                    <span>{{ material.ammount }} {{ material.unity }}</span>
                  </h4>
                  <p>Coletado dia {{ material.colletion_date }}</p>

                  <div class="material__item--footer collected__item--footer">
                    <a
                      class="material__item--anchor"
                      :class="{ disable: material.nota > 0 }"
                      href="#"
                      @click.prevent.stop="openModal(material)"
                      title="Avaliar"
                    >
                      <i class="fas fa-star" v-if="material.nota > 0"></i>
                      <i class="far fa-star" v-if="material.nota == 0"></i>
                      {{ material.nota == 0 ? 'Avaliar' : 'Avaliado' }}
                    </a>

                    <a
                      class="material__item--anchor"
                      :href="
                        `https://apipainel.recicla.com.br/api/certificado/generatePDF/${material.id}`
                      "
                      title="Certificado"
                    >
                      <i class="icon icon-award"></i> Certificado
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal-info
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      :selectedOrder.sync="selectedOrder"
    ></modal-info>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  data: () => ({
    materials: [],
    modal: false,
    search: '',
    error: null,
    selectedOrder: {},
    load: true,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    startDate: '',
    endDate: ''
  }),
  components: {
    modalInfo: () => import('./components/modal-info-review')
  },
  methods: {
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value
    }, 300),
    openModal (item) {
      if (item.nota > 0) return
      this.selectedOrder = item
      this.modal = true
    },
    async getCollectedMaterials () {
      this.load = true
      this.error = null

      try {
        let url = `collection/allOrdersByGenerator?iduser=${this.user.id}&search=${this.search}`
        if (this.endDate && this.startDate) {
          const dateUrl = `&startDate=${this.startDate}&endDate=${this.endDate}`
          url = url + dateUrl
        }

        const { data } = await this.$http.get(url)
        if (data.data.length > 0) this.materials = data.data
        else this.error = 'Não foi possivel encontrar materiais coletados.'
      } catch (error) {
        this.error = error.response.data
      } finally {
        this.load = false
      }
    }
  },
  watch: {
    search (val) {
      if (val.length < 3 && val.length > 0) {
        return false
      }
      this.getCollectedMaterials()
    },
    modal (val) {
      if (!val) this.getCollectedMaterials()
    },
    startDate (val) {
      if (val && this.endDate) this.getCollectedMaterials()
    },
    endDate (val) {
      if (val && this.startDate) this.getCollectedMaterials()
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.getCollectedMaterials()
  }
}
</script>

<style>
.disable {
  color: #ebbb00;
}
</style>
